import * as React from 'react'

import { Container } from '@mui/material'

import { AuthRegister } from 'shared/pages'
import { getQueryStringValue } from 'shared/services'

import Dinosaur from '../../components/dinosaur'
import Meteorite from '../../components/meteorite'
import Layout from '../../layouts/landing'

import type { PageProps } from 'gatsby'

const SITE_NAME = process.env.GATSBY_SITE_NAME

const Register = ({ location }: PageProps) => {
  const originName = getQueryStringValue(location.search, 'campaign', 'signup_form')
  const [showMeteorite, setShowMeteorite] = React.useState(false)

  const title = (originName === 'payment_card')
    ? `Regístrate en ${SITE_NAME} y obtén acceso anticipado a Sugar-Card`
    : `Regístrate en ${SITE_NAME}`

  const description = (
    'Regístrate para acceder y administrar las criptomonedas de tu portafolio.'
    + ' Plataforma que te permite tener tecnología blockchain a tu alcance.'
  )

  return (
    <Layout>
      <AuthRegister
        title={title}
        description={description}
        originName={originName}
        onRegistrationSuccess={() => setShowMeteorite(true)}
        registrationDialogTimeout={4000}
      />
      <Container
        maxWidth='xs'
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          transition: 'opacity 6s ease-out',
          opacity: showMeteorite ? 0 : 1,
        }}
      >
        <Dinosaur />
      </Container>
      <Meteorite animate={showMeteorite} />
    </Layout>
  )
}

export default Register
