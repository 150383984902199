import * as React from 'react'

import { useTheme } from '@mui/material'

import MeteoriteImg from '../images/landing/crypto-meteorite.svg'

type MeteoriteProps = {
  animate: boolean
}

const Meteorite = ({ animate }: MeteoriteProps) => {
  const image = React.useRef<HTMLImageElement>(null)

  const [loaded, setLoaded] = React.useState(false)

  const handleLoad = () => setLoaded(true)

  const applyAnimation = animate && loaded
  const targetOpacity = useTheme().palette.mode === 'light' ? 0.2 : 0.6

  const style: React.CSSProperties = {
    transitionProperty: 'right, bottom, opacity',
    transitionDuration: '3000ms, 3000ms, 3000ms',
    transitionTimingFunction: 'cubic-bezier(.49, .18, .81, .45)',
    opacity: applyAnimation ? targetOpacity : 1,
    right: applyAnimation ? '-100vh' : '100vh',
    bottom: applyAnimation ? '-100vh' : '100vh',
    height: '100vh',
    position: 'fixed',
    filter: 'blur(5px)',
    zIndex: -1,
  }

  React.useEffect(() => {
    const currentImage = image.current

    if (currentImage && currentImage.complete) {
      handleLoad()
    }
  }, [])

  return (
    <img
      ref={image}
      src={MeteoriteImg}
      alt='Meteorito Bitcoin'
      style={style}
      onLoad={handleLoad}
    />
  )
}

export default Meteorite
